body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*  Scrollbar*/
@media screen and (min-width: 600px) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 6px;
  }

  ::-webkit-scrollbar:hover {
    width: 10px;
    height: 6px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: 0px solid #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #f1f1f1;
}
::-webkit-scrollbar-track:active {
  background: #a4d6e2;
}

/* Thumb */
::-webkit-scrollbar-thumb {
  background: #d4d3d3;
  border: 0px none #ffffff;
  border-radius: 83px;
}
::-webkit-scrollbar-thumb:hover {
  background: #888;
}
::-webkit-scrollbar-thumb:active {
  background: #117893;
}

/* Corner */
::-webkit-scrollbar-corner {
  background: transparent;
}

/* Button */
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
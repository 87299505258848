@keyframes ldio-7bfvs2czilc {
  0% { opacity: 1 }
  50% { opacity: .5 }
  100% { opacity: 1 }
}
.ldio-7bfvs2czilc div {
  position: fixed;
  width: 20px;
  height: 80px;
  /* top: 60px; */
  margin: auto;
  animation: ldio-7bfvs2czilc 1s cubic-bezier(0.5,0,0.5,1) infinite;
}.ldio-7bfvs2czilc div:nth-child(1) {
  transform: translate(30px,0);
  background: #e15b64;
  animation-delay: -0.6s;
}.ldio-7bfvs2czilc div:nth-child(2) {
  transform: translate(70px,0);
  background: #f47e60;
  animation-delay: -0.4s;
}.ldio-7bfvs2czilc div:nth-child(3) {
  transform: translate(110px,0);
  background: #f8b26a;
  animation-delay: -0.2s;
}.ldio-7bfvs2czilc div:nth-child(4) {
  transform: translate(150px,0);
  background: #abbd81;
  animation-delay: -1s;
}
.outer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1500;
  position: absolute;
  justify-content: 'center'; 
  align-items: 'center'; 
  background: rgba(255,255,255,0.8);
  display: flex;
    /* display: inline-block; */
}
.ldio-7bfvs2czilc {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-7bfvs2czilc div { box-sizing: content-box; }
